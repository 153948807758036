<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('배팅 상세 목록'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'transaction/details',
      response: '',
      body: {
        vendorKey: { type: 'onlyVendors', value: '', desc: this.$t('text.api.vendorKey_desc'), required: true },
        beginDate: {type: 'date', value: null, desc: this.$t('text.api.beginDate_desc'), required: true},
        endDate: {type: 'date', value: null, desc: this.$t('text.api.endDate_desc'), required: false},
        limit: {type: 'number', value: '100', desc: this.$t('text.api.limit_desc'), required: true}
      }
    };
  },
  mounted() {
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }

     */
    const host = process.env.VUE_APP_API_SERVER_URL
    this.apiUrl = host + 'transaction/details'

    // 응답값 설정
    this.response = '{\n' +
        ' "code": 0, \t\t\t\t\t\t\t// 응답값(0이 아니면 오류)\n' +
        ' "length": 519 \t\t\t\t\t\t\t// 갯수(최대 2,000개),\n' +
        ' "beginDate": "2022-08-30T16:29:00.455Z", \t\t\t// 다음 검색 시작일자(UTC Time = 한국시간 - 9시간 )\n' +
        ' "transactions": [ \t\t\t\t\t\t// 목록\n' +
        '  {\n' +
        '    "_id": "637ae6501fab1563400b9024",\n' +
        '    "transactionKey": "53303afb7f75e7ce6d8a8f83e301322a",\n' +
        '    "createdAt": "2022-11-21T02:44:55.723Z",\n' +
        '    "vendorKey": "pragmatic_slot",\n' +
        '    "detail": {\n' +
        '    .\n' +
        '    .\n' +
        '    .\n' +
        '    }\n' +
        '  },\n' +
        '  .\n' +
        '  .\n' +
        '  .\n' +
        ' ]\n' +
        '}'
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="card-title"><span className="badge bg-primary">요청 URL</span> {{ apiUrl }}</div>
            <div className="card-title-desc">{{ $t('text.api_transactions_desc')}}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="true"
                      :require-ip-address="false" :require-request-key="false"/>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
